<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">

      <a-form-model-item label="车辆编号" prop="vehicleId" >
        <a-input v-model="form.vehicleId" placeholder="" disabled />
      </a-form-model-item>

      <a-form-model-item label="开始时间" prop="taskStartTime" >
        <a-date-picker disabled style="width: 100%" v-model="form.taskStartTime" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="结束时间" prop="taskEndTime" >
        <a-date-picker disabled style="width: 100%" v-model="form.taskEndTime" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>

      <a-form-model-item label="地图ID" prop="mapId" >
        <a-input disabled v-model="form.mapId" placeholder="" />
      </a-form-model-item>
      <a-form-model-item label="地图版本" prop="mapVersion" >
        <a-input disabled v-model="form.mapVersion" placeholder="" />
      </a-form-model-item>
      <a-form-model-item label="任务进度 " prop="taskProgress" >
        <a-input disabled :value="form.taskProgress+'%'" placeholder="" />
      </a-form-model-item>
      <a-form-model-item label="车端业务状态" prop="state" >
        <a-input disabled :value="selectDictLabel(bizStateOptions, form.state)" placeholder="" />
      </a-form-model-item>
      <a-form-model-item label="任务下发结果" prop="taskStart" >
        <a-input disabled :value="selectDictLabel(vehicleRemoteCtlResultOptions, form.taskStart)" placeholder="" />
      </a-form-model-item>
      <a-form-model-item label="任务状态" prop="taskState" >
        <a-input disabled :value="selectDictLabel(taskStateOptions, form.taskState)" placeholder="" />
      </a-form-model-item>
      <a-form-model-item label="坐标点经度" prop="lon" >
        <a-input disabled v-model="form.lon" placeholder="" />
      </a-form-model-item>
      <a-form-model-item label="坐标点纬度" prop="lat" >
        <a-input v-model="form.lat" placeholder="" />
      </a-form-model-item>
      <a-form-model-item label="任务完成里程" prop="finishedOdom" >
        <a-input disabled v-model="form.finishedOdom" placeholder="" />
      </a-form-model-item>
      <a-form-model-item label="任务完成时长(单位：秒)" prop="finishedTime" >
        <a-input disabled v-model="form.finishedTime" placeholder="" />
      </a-form-model-item>
      <a-form-model-item label="任务开始时刻" prop="vehicleStartTime" >
        <a-input disabled v-model="form.vehicleStartTime" placeholder="" />
      </a-form-model-item>
      <a-form-model-item label="任务结束时刻" prop="vehicleEndTime" >
        <a-input disabled v-model="form.vehicleEndTime" placeholder="" />
      </a-form-model-item>
      <a-form-model-item label="当天完成的任务次数(第几次)" prop="tasksNumberDay" >
        <a-input disabled v-model="form.tasksNumberDay" placeholder="" />
      </a-form-model-item>
      <a-form-model-item label="当天执行的任务次数" prop="tasksExeNumberDay" >
        <a-input disabled v-model="form.tasksExeNumberDay" placeholder="" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="dashed" @click="cancel">
            退出
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getVehicleTaskRecord } from '@/api/iot/vehicleTaskRecord'

export default {
  name: 'CreateForm',
  props: {
    bizStateOptions: {
      type: Array,
      default: () => {}
    },
    taskStateOptions: {
      type: Array,
      default: () => {}
    },
    vehicleRemoteCtlResultOptions: {
      type: Array,
      default: () => {}
    }
  },
  components: {
  },
  data () {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        raTaskId: null,
        vehicleId: null,
        taskSchedulerId: null,
        taskStartTime: null,
        taskEndTime: null,
        vehicleTaskId: null,
        mapId: null,
        mapVersion: null,
        taskProgress: null,
        taskExeId: null,
        state: null,
        taskStart: null,
        taskState: null,
        seq: null,
        status: 0,
        lon: null,
        lat: null,
        finishedOdom: null,
        finishedTime: null,
        vehicleStartTime: null,
        vehicleEndTime: null,
        taskFinishedFlag: null,
        taskPauseInfos: null,
        tasksNumberDay: null,
        tasksExeNumberDay: null,
        tenantId: null,
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        raTaskId: [
          { required: true, message: '中控任务ID不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        raTaskId: null,
        vehicleId: null,
        taskSchedulerId: null,
        taskStartTime: null,
        taskEndTime: null,
        vehicleTaskId: null,
        mapId: null,
        mapVersion: null,
        taskProgress: null,
        taskExeId: null,
        state: null,
        taskStart: null,
        taskState: null,
        seq: null,
        status: 0,
        lon: null,
        lat: null,
        finishedOdom: null,
        finishedTime: null,
        vehicleStartTime: null,
        vehicleEndTime: null,
        taskFinishedFlag: null,
        taskPauseInfos: null,
        tasksNumberDay: null,
        tasksExeNumberDay: null,
        tenantId: null,
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null
      }
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getVehicleTaskRecord(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '查看'
      })
    },
    /** 提交按钮 */
    submitForm: function () {

    }
  }
}
</script>
