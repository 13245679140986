var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆编号", prop: "vehicleId" } },
            [
              _c("a-input", {
                attrs: { placeholder: "", disabled: "" },
                model: {
                  value: _vm.form.vehicleId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "vehicleId", $$v)
                  },
                  expression: "form.vehicleId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "开始时间", prop: "taskStartTime" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  disabled: "",
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD HH:mm:ss",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.taskStartTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "taskStartTime", $$v)
                  },
                  expression: "form.taskStartTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "结束时间", prop: "taskEndTime" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  disabled: "",
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD HH:mm:ss",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.taskEndTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "taskEndTime", $$v)
                  },
                  expression: "form.taskEndTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "地图ID", prop: "mapId" } },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "" },
                model: {
                  value: _vm.form.mapId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "mapId", $$v)
                  },
                  expression: "form.mapId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "地图版本", prop: "mapVersion" } },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "" },
                model: {
                  value: _vm.form.mapVersion,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "mapVersion", $$v)
                  },
                  expression: "form.mapVersion",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "任务进度 ", prop: "taskProgress" } },
            [
              _c("a-input", {
                attrs: {
                  disabled: "",
                  value: _vm.form.taskProgress + "%",
                  placeholder: "",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "车端业务状态", prop: "state" } },
            [
              _c("a-input", {
                attrs: {
                  disabled: "",
                  value: _vm.selectDictLabel(
                    _vm.bizStateOptions,
                    _vm.form.state
                  ),
                  placeholder: "",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "任务下发结果", prop: "taskStart" } },
            [
              _c("a-input", {
                attrs: {
                  disabled: "",
                  value: _vm.selectDictLabel(
                    _vm.vehicleRemoteCtlResultOptions,
                    _vm.form.taskStart
                  ),
                  placeholder: "",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "任务状态", prop: "taskState" } },
            [
              _c("a-input", {
                attrs: {
                  disabled: "",
                  value: _vm.selectDictLabel(
                    _vm.taskStateOptions,
                    _vm.form.taskState
                  ),
                  placeholder: "",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "坐标点经度", prop: "lon" } },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "" },
                model: {
                  value: _vm.form.lon,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "lon", $$v)
                  },
                  expression: "form.lon",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "坐标点纬度", prop: "lat" } },
            [
              _c("a-input", {
                attrs: { placeholder: "" },
                model: {
                  value: _vm.form.lat,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "lat", $$v)
                  },
                  expression: "form.lat",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "任务完成里程", prop: "finishedOdom" } },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "" },
                model: {
                  value: _vm.form.finishedOdom,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "finishedOdom", $$v)
                  },
                  expression: "form.finishedOdom",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: { label: "任务完成时长(单位：秒)", prop: "finishedTime" },
            },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "" },
                model: {
                  value: _vm.form.finishedTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "finishedTime", $$v)
                  },
                  expression: "form.finishedTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "任务开始时刻", prop: "vehicleStartTime" } },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "" },
                model: {
                  value: _vm.form.vehicleStartTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "vehicleStartTime", $$v)
                  },
                  expression: "form.vehicleStartTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "任务结束时刻", prop: "vehicleEndTime" } },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "" },
                model: {
                  value: _vm.form.vehicleEndTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "vehicleEndTime", $$v)
                  },
                  expression: "form.vehicleEndTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "当天完成的任务次数(第几次)",
                prop: "tasksNumberDay",
              },
            },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "" },
                model: {
                  value: _vm.form.tasksNumberDay,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "tasksNumberDay", $$v)
                  },
                  expression: "form.tasksNumberDay",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: { label: "当天执行的任务次数", prop: "tasksExeNumberDay" },
            },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "" },
                model: {
                  value: _vm.form.tasksExeNumberDay,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "tasksExeNumberDay", $$v)
                  },
                  expression: "form.tasksExeNumberDay",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 退出 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }